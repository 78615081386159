import React, { useState, useEffect, useContext } from 'react';

import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

import Settings from '../Settings';

export const GlobalConfigContext = React.createContext({});

function GlobalConfigProvider(props) {
    const [globalConfig, setGlobalConfig] = useState({
        regions: [],
        countries: [],
        sustainableDevelopmentGoals: [],
    });

    useEffect(() => {
        HttpGet(`${Settings.apiUrls.globalConfig}/regions`).then((response) =>
            setGlobalConfig((prevGlobalConfig) => {
                return { ...prevGlobalConfig, regions: response.entities };
            })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/countries`).then((response) =>
            setGlobalConfig((prevGlobalConfig) => {
                return { ...prevGlobalConfig, countries: response.entities };
            })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/data/sdgs`).then((response) =>
            setGlobalConfig((prevGlobalConfig) => {
                return { ...prevGlobalConfig, sustainableDevelopmentGoals: response.entities };
            })
        );
    }, []);

    return (
        <GlobalConfigContext.Provider value={globalConfig}>
            {props.children}
        </GlobalConfigContext.Provider>
    );
}

export function useGlobalConfigState() {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useGlobalConfigState must be used within a GlobalConfigProvider');
    }
    return context;
}

export function useRegions() {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useRegions must be used within a GlobalConfigProvider');
    }
    return context.regions;
}

export function useCountries() {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useCountries must be used within a GlobalConfigProvider');
    }
    return context.countries;
}

export function useSustainableDevelopmentGoals() {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useCountries must be used within a GlobalConfigProvider');
    }
    return context.sustainableDevelopmentGoals;
}

export default GlobalConfigProvider;
