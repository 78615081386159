import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import LoadScreen from 'ibis-design-system/lib/components/core/LoadScreen';
import LoginProvider from 'ibis-design-system/lib/Providers/LoginProvider';
import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import IEPage, { isUsingInternetExplorer } from 'ibis-design-system/lib/components/core/IEPage';

import * as serviceWorker from './serviceWorker';
import AppContainer from './AppContainer';

import Settings, { SetSettings } from './Settings';
import ThemeProvider from 'ibis-design-system/lib/Providers/ThemeProvider';

if (isUsingInternetExplorer()) {
    ReactDOM.render(<IEPage />, document.getElementById('root'));
} else {
    let settingsPath = 'appSettings.json';
    if (process.env.NODE_ENV === 'development') settingsPath = 'appSettings.dev.json';

    HttpGet(`${window.location.origin}/${settingsPath}`).then((response) => {
        SetSettings(response);

        ReactDOM.render(
            <ThemeProvider>
                <LoginProvider
                    api={Settings.apiUrls.calendars}
                    ssoApi={Settings.apiUrls.singleSignOn}
                    sso={Settings.uiUrls.singleSignOn}
                    returnUrl={window.location.href}
                    logApi={Settings.apiUrls.portal}
                >
                    {/* Only load the application when the user has done the login */}
                    <Suspense
                        fallback={
                            <div style={{ height: '100vh' }}>
                                <LoadScreen />
                            </div>
                        }
                    >
                        <AppContainer />
                    </Suspense>
                </LoginProvider>
            </ThemeProvider>,
            document.getElementById('root')
        );
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
