import React, { Suspense, lazy } from 'react';

import LoadScreen from 'ibis-design-system/lib/components/core/LoadScreen';
import HttpCacheProvider from 'ibis-design-system/lib/Providers/HttpCacheProvider';
import ApplicationsProvider from 'ibis-design-system/lib/Providers/ApplicationsProvider';
import GoogleMapsProvider from 'ibis-design-system/lib/Providers/GoogleMapsProvider';
import NavigationProvider from 'ibis-design-system/lib/Providers/NavigationProvider';

import Settings from './Settings';
import GlobalConfigProvider from './Providers/GlobalConfigProvider';

const App = lazy(() => import('./App'));

function AppContainer(props) {
    return (
        <HttpCacheProvider>
            <ApplicationsProvider portalApi={Settings.apiUrls.portal}>
                <GlobalConfigProvider>
                    <GoogleMapsProvider apiKey={Settings.mapsKey}>
                        <NavigationProvider
                            defaultCollection="events"
                            defaultCollectionTab="Calendar"
                        >
                            <Suspense
                                fallback={
                                    <div style={{ height: '100vh' }}>
                                        <LoadScreen />
                                    </div>
                                }
                            >
                                <App />
                            </Suspense>
                        </NavigationProvider>
                    </GoogleMapsProvider>
                </GlobalConfigProvider>
            </ApplicationsProvider>
        </HttpCacheProvider>
    );
}

export default AppContainer;
